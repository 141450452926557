import LandingLogo from "../images/venus-landing-logo.png";
import Alert from "../images/alert.png";
import CatEyes from "../images/cat-eyes.png";
import FireHeart from "../images/fire-heart.png";
import LipBite from "../images/lip-bite.png";
import MonkeyGasp from "../images/monkey-gasp.png";
import Sweaty from "../images/sweaty.png";
import Wet from "../images/wet.png";
import { Link } from "react-router-dom";
import axios from 'axios';
import React, {useEffect, useState} from 'react';

function Landing() {
    const DOMAIN = "https://api.stanfordvenus.com";
    const [rows, setRows] = useState([]);

    useEffect(() => {
        axios.get(`${DOMAIN}/matchmaker/leaderboard/?school=mit.edu`)
        .then(resp => {
            console.log(resp)
            setRows(resp.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <div className="flex flex-col content-center">
            <div className="basis-1/16 bg-gray-500 p-2 text-center">
                <p className="text-sm md:text-md lg:text-md text-white">We value your privacy! View our privacy policy <a href="/privacy" className="text-blue-300 hover:underline">here</a>.</p>
            </div>
            <div className="basis-1/8">
                <img src={LandingLogo} className="w-64 md:w-84 cursor-pointer m-4"/>
            </div>
            <div className="text-red-600 text-2xl text-center">Venus @ MIT has been shut down. The Bulletin Board has been taken down.</div>
            {/* <div className="text-red-600 text-2xl text-center">Venus experienced an unanticipated level of traffic and is temporarily being renovated. We will send an email when we are back up.</div> */}
            <div className="basis-1/4 container mx-auto p-8 m-2 w-7/8 md:w-3/4">
                <div className="flex flex-row h-28 md:h-48">
                    <div className="basis-1/3 relative">
                        <img src={MonkeyGasp} className="w-16 md:w-20 lg:w-28 absolute bottom-0 left-0"/>
                    </div>
                    <div className="basis-1/3 relative">
                        <img src={Alert} className="w-14 md:w-16 lg:w-24 absolute top-0 left-0"/>
                    </div>
                    <div className="basis-1/3 relative">
                        <img src={Sweaty} className="w-12 md:w-16 lg:w-24 absolute inset-y-9 right-4 md:inset-y-16 md:right-14"/>
                    </div>
                </div>
                <h1 className="lg:text-7xl md:text-6xl text-5xl font-bold mb-5 text-center" style={{color:"#0500FF"}}>
                    Let's let them know...
                </h1>
                <div className="flex flex-row h-24 md:h-36">
                    <div className="basis-1/2 relative">
                        <img src={CatEyes} className="w-14 md:w-20 lg:w-28 absolute top-0 left-4 md:left-8"/>
                    </div>
                    <div className="basis-1/3 md:basis-1/4 flex flex-col">
                        <div className="basis-1/2 relative">
                            <img src={LipBite} className="w-14 md:w-16 lg:w-24 absolute top-0 left-0"/>
                        </div>
                        <div className="basis-1/2 relative">
                            <img src={Wet} className="w-12 md:w-16 lg:w-24 absolute top-0 right-0"/>
                        </div>
                    </div>
                    <div className="basis-1/6 md:basis-1/4 relative">
                        <img src={FireHeart} className="w-14 md:w-16 lg:w-24 absolute top-0 right-0"/>
                    </div>
                </div>
            </div>
            <div className="container mx-auto basis-1/4 m-6 flex flex-col items-center">
                {/* <Link to="/email">
                    <button className="w-64 rounded-xl shadow p-3 text-center transition duration-500 hover:scale-105" style={{background:"#EB1F1F"}}>
                        <p className="text-lg" style={{color: "white"}}>Sign in</p>
                    </button>
                </Link> */}
                {/* <p className="m-2 text-md cursor-pointer">Your privacy matters. ⓘ</p> */}
            </div>
            <div className="container mx-auto basis-1/4 flex flex-col items-center m-4">
                <p className="text-center text-3xl m-4">What's Venus?</p>
                <p className="text-center text-md lg:text-lg w-3/4">Think that two of your friends are meant to be but just don’t know it? Venus is an anonymous “shipping” platform between students at MIT. People who get paired up are sent an email and will remain on a public bulletin board (until they give it a shot 😉). You can upvote ongoing ships or post your own!</p>
            </div>
            <div className="container mx-auto basis-1/4 flex flex-col items-center m-4 w-5/6 md:w-3/4">
                <p className="text-center text-3xl m-4">See who's meant to be.</p>
                <div className="container mx-auto rounded-xl border-solid border-4 border-black px-8 py-5 m-2 flex flex-col divide-y-[3px] divide-black" style={{background:"#FF6060"}}>
                    <div className="text-sm md:text-md lg:text-lg flex flex-row justify-between py-2">
                        <div>Person 1  ❤️  Person 2</div>
                        <div>Upvotes</div>
                    </div>
                    {/* {rows.map(row => {
                        return (
                            <div className="text-sm md:text-md text-white flex flex-row justify-between py-3">
                                <div>{row.names.split("<3").join('  ❤️  ')}</div>
                                <div>{row.upvotes}</div>
                            </div>
                        );
                    })} */}
                    <></>
                </div>
            </div>
            <div className="container mx-auto basis-1/4 m-10 flex flex-col items-center">
                {/* <Link to="/email">
                    <button className="w-64 rounded-xl shadow p-3 text-center transition duration-500 hover:scale-105" style={{background:"#EB1F1F"}}>
                        <p className="text-lg" style={{color: "white"}}>Sign in</p>
                    </button>
                </Link> */}
            </div>
            <div className="basis-1/4 p-6 text-sm md:text-md lg:text-md" style={{background: "#FE9999"}}>
                <span>Created by and for MIT students, but not officially associated with MIT. Have a question? <a href="mailto: venus@mit.edu" className="text-blue-600 hover:underline dark:text-blue-500">Contact us.</a></span>
                {/* <p className="text-sm md:text-md lg:text-md">Built by Liam Kronman, Sofiane Larbi, and Jason Seo.</p> */}
            </div>
        </div>
    );
}

export default Landing;