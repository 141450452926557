import {Link, useNavigate} from "react-router-dom";
import AuthLogo from "../images/venus-auth-logo.png";
import React, {useEffect, useState} from 'react';
import axios from 'axios';


function Code({setAuth}) {
    const DOMAIN = "https://api.stanfordvenus.com";
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const navigate = useNavigate();
    const email = window.localStorage.getItem('email');
    const [resent, setResent] = useState(false);
    const [error, setError] = useState("");

    function OTPInput() {
        const inputs = document.querySelectorAll('#otp > *[id]');
        for (let i = 0; i < inputs.length; i++) { 
            inputs[i].addEventListener('keydown', function(event) { 
                if (event.key==="Backspace" ) { 
                    inputs[i].value='' ; 
                    setCode(code => {
                        let newCode = code.slice(0,i-1);
                        newCode.push('');
                        return newCode.concat(code.slice(i));
                    });
                    if (i !==0) inputs[i - 1].focus(); 
                } else {
                    if (i===inputs.length - 1 && inputs[i].value !=='' ) { 
                        return true; 
                    } else if (event.keyCode> 47 && event.keyCode < 58) { 
                        inputs[i].value=event.key;
                        setCode(code => {
                            let newCode = code.slice(0,i);
                            newCode.push(event.key);
                            return newCode.concat(code.slice(i+1));
                        });
                        if (i !==inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault(); 
                    } else if (event.keyCode> 64 && event.keyCode < 91) { 
                        inputs[i].value=String.fromCharCode(event.keyCode);
                        if (i !==inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault(); 
                    } 
                } 
            }); 
        } 
    }

    useEffect(() => {
        OTPInput();
    }, []);

    useEffect(() => {
        let filled = true;
        for (let i = 0; i < 6; i++) {
            if (code[i] === '') {
                filled = false;
                break;
            }
        }
        if (filled) {
            let token = code.join("");
            axios.post(`${DOMAIN}/auth/token/`, {
                email: email,
                token: token
            })
            .then(resp => {
                // console.log(resp.data.token);
                setError("");
                setAuth(resp.data.token);
                window.localStorage.setItem('token', resp.data.token);
                navigate('/');
            })
            .catch(err => {
                setError("Something went wrong...")
            })
        }
    }, [code]);

    const resendCode = () => {
        axios.post(`${DOMAIN}/auth/email/`, {
            email: email
        })
        .then(resp => {
            setResent(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (
        <div>
             <div className="basis-1/4 p-4 flex flex-col md:flex-row justify-between" style={{background: "#FE9999", color: "white"}}>
                <Link to="/"><img src={AuthLogo} className="w-64 md:w-84 cursor-pointer" /></Link>
            </div>
            <div className="container m-auto p-8 text-center">
                <div className="max-w-sm mx-auto md:max-w-lg">
                    <div className="w-full">
                        <div className="bg-white h-64 py-3 rounded-xl text-center p-2">
                            <h1 className="text-2xl font-bold">Email Verification</h1>
                            <div className="flex flex-col mt-4 text-sm">
                                <span>Enter the code you received at your MIT email address. (This may take a few moments.)</span>
                            </div>
                            
                            <div id="otp" className="flex flex-row justify-center text-center px-2 mt-5">
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="first" maxLength="1" /> 
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="second" maxLength="1" /> 
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="third" maxLength="1" /> 
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="fourth" maxLength="1" />
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="fifth" maxLength="1" /> 
                                <input className="m-1 md:m-2 border h-10 w-10 text-center form-control rounded" type="number" id="sixth" maxLength="1" />
                            </div>
                            {error !== "" && <div className="text-sm text-red-600">{error}</div>}
                            
                            <div className="flex justify-center text-center mt-1">
                                {
                                    resent
                                    ? <p className="text-sm mx-2 text-gray-700">Another code has been sent! Wait a few moments and check your email.</p>
                                    : <a className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer" onClick={resendCode}><span className="font-bold">Resend Code</span><i className='bx bx-caret-right ml-1'></i></a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Code;