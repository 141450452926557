import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, {useState,useEffect} from 'react';

function Profile({setAuth}) {
    const navigate = useNavigate();
    const DOMAIN = "https://api.stanfordvenus.com";
    const token = window.localStorage.getItem('token');
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        axios.get(`${DOMAIN}/users/profile/`, {
        headers: {
            Authorization: `Token ${token}`
        }
    })
    .then(resp => {
        setProfile(resp.data);
    })
    .catch(err => {
        console.log(err);
    })}, []);

    return (
        <div className="container mx-auto m-4 p-4 flex flex-col">
                {
                    (profile !== null) && <>
                    <div className="flex flex-col md:flex-row">
                        <div className="basis-1/3 flex flex-col text-center p-4">
                            <h1 className="text-2xl">Matchmaker<span className="text-red-500 text-md">*</span></h1>
                            <p className="text-sm">(Score based on upvotes of your posted pairs)</p>
                            <p className="text-lg text-gray-500">{profile.matchmaker_score} points</p>
                            <p className="text-lg text-green-500">#{profile.matchmaker_rank} on MIT Campus</p>
                        </div>
                        <div className="basis-1/3 flex flex-col text-center p-4">
                            <h1 className="text-2xl">Wingman<span className="text-red-500 text-md">*</span></h1>
                            <p className="text-sm">(Score based on your upvotes)</p>
                            <p className="text-lg text-gray-500">{profile.wingman_score} points</p>
                            <p className="text-lg text-green-500">#{profile.wingman_rank} on MIT Campus</p>
                        </div>
                        <div className="basis-1/3 flex flex-col text-center p-4">
                            <h1 className="text-xl">Active Dater<span className="text-red-500 text-md">*</span></h1>
                            <p className="text-sm">(Score based on number of successful dates)</p>
                            <p className="text-lg text-gray-500">{profile.dater_score} points</p>
                            <p className="text-lg text-green-500">#{profile.dater_rank} on MIT Campus</p>
                        </div>
                    </div>
                    </>
                }
            <div className="text-center m-8">
                <button className="text-white w-48 bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => {
                    setAuth("");
                    navigate("/");
                }}>Logout</button>
            </div>
            {(profile !== null) && <p className="text-sm p-4"><span className="text-red-500 text-md">*</span> Updated after approval of selfie from dates.</p>}
        </div>
    );
}

export default Profile;