import AuthLogo from "../images/venus-auth-logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import React, {useState} from 'react';

function Email() {
    const DOMAIN = "https://api.stanfordvenus.com";
    const [inputEmail, setInputEmail] = useState("");
    const [failed, setFailed] = useState(false);

    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setInputEmail(event.target.value);
    }

    const sendEmail = () => {
        if (inputEmail.endsWith("@mit.edu")) {
            setFailed(false);
            axios.post(`${DOMAIN}/auth/email/`, {
                email: inputEmail
            })
            .then(resp => {
                window.localStorage.setItem('email', inputEmail);
                navigate("/code");
            })
            .catch(err => {
                console.log(err);
            })
        } else {
            setFailed(true);
            setInputEmail("");
        }
    }

    return (
        <div>
            <div className="basis-1/4 p-4 flex flex-col md:flex-row justify-between" style={{background: "#FE9999", color: "white"}}>
                <Link to="/"><img src={AuthLogo} className="w-64 md:w-84 cursor-pointer" /></Link>
            </div>
            <div className="container m-auto p-8 text-center">
                <h1 className="text-3xl">Sign In</h1>
                <h3 className="text-1xl py-2 text-left">Your MIT Email</h3>
                <div className="relative text-left">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                        </div>
                        <input type="text" id="email" className={(failed && inputEmail === "") ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg block w-full pl-10 p-2.5 focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"} placeholder="kerb@mit.edu" onChange={handleEmailChange}/>
                    </div>
                    { failed && <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium">Oh, snap!</span> Please enter a valid MIT email address.</p> }
                    <p id="helper-text-explanation" className="text-left mt-2 text-sm text-gray-600 dark:text-gray-600">We’ll never share your details. Read our <Link to="/privacy" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Privacy Policy</Link>.</p>
                    {/* <div className="flex items-center">
                        <input id="checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"/>
                        <label for="checkbox" className="ml-2 text-sm font-medium">I consent to being sent a login code by Venus @ MIT.</label>
                    </div> */}
                    <button type="submit" className="mt-2 align-left text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={sendEmail}>Next</button>
                </div>
            </div>
        </div>
    );
}

export default Email;