import { Link } from "react-router-dom";
import AuthLogo from "../images/venus-auth-logo.png";

function Privacy() {
    return(
        <div>
            <div className="basis-1/4 p-4 flex flex-col md:flex-row justify-between" style={{background: "#FE9999", color: "white"}}>
                <Link to="/"><img src={AuthLogo} className="w-64 md:w-84 cursor-pointer" /></Link>
            </div>
            <div className="container m-auto p-8 text-center">
                <h1 className="text-3xl">Privacy Policy</h1>
                <p className="text-md p-4 text-center px-4 md:px-32 lg:px-48">Venus is a platform that operates anonymously, so the individuals you post or upvote about will not be aware of your participation. The only personal information we retain is users' email addresses (no passwords).</p>
                <p className="text-md p-4 text-center px-4 md:px-32 lg:px-48">Should you wish for us to delete your information completely, please send an email to <a href="mailto: venus@mit.edu" className="text-blue-600 hover:underline dark:text-blue-500">venus@mit.edu</a>. It's important to note that this site is created with a lighthearted spirit, and we encourage those featured on the Bulletin Board to view their listings with a sense of humor. However, in cases where malicious intent is suspected, we may make exceptions.</p>
            </div>
        </div>
    );
}

export default Privacy;